<template>
<div class="page-about">
  <comm-banner :text="$t('menu.about')" />
  <div class="about-box">
<!--    <div class="comm-title">公司介绍</div>-->
    <div class="about-text">
        <div>
          <div class="about-text-title"></div>
          <div :class="{'lang':lang==='en'}">
            <p>{{$t('pageAbout.text1')}}</p>
            <p>{{$t('pageAbout.text2')}}</p>
            <p>{{$t('pageAbout.text3')}}</p>
          </div>
        </div>
<!--      <p>-->
<!--        风物资本，一家专注于跨境创新的风险投资基金，致力于将全球创新与中国市场紧密连接。于上海、新加坡、东京设有办公室。团队逾十五年的投资经验和超五十亿美金市值的投资实例，是风物资本“放眼世界，服务中国”愿景的保障。我们一方面帮助海外创新团队落地中国，另一方面助力中国企业开拓境外市场，走向世界。-->
<!--      </p>-->
        <!--
 <p>风物资本是聚焦中国视角，专注跨境创新的早期风险投资基金。</p>-->
<!--      <p>风物资本在新加坡、上海、东京分别设立办公室，关注全球范围的创新科技应用和新兴消费品牌。</p>-->
<!--      <p>风物资本核心管理团队均来自于著名投资机构和家族办公室，拥有超过15年的海外投资经验和累计50亿美金市值的跨境投资案例。</p>-->
<!--      <p>风物资本的愿景为——放眼全球创新，服务中国增长。</p>-->
<!--      <p>我们一方面投资海外资产，协助华人科学家和创业团队落地中国，另一方面帮助国内创业团队覆盖全球销售。</p>-->
    </div>
    <home-case />
  </div>
</div>
</template>

<script>
import HomeCase from "../../components/HomeCase";
import CommBanner from "@/components/CommBanner";
import {mapState} from "vuex/dist/vuex.esm.browser";
export default {
  name: "About",
  components: {CommBanner, HomeCase},
  computed:{
    ...mapState(['lang'])
  }
}
</script>

<style lang="scss" scoped>
.page-about {

  .about-banner {
    height: 600px;
    background: url("./images/about-banner.png") no-repeat center;
    background-size: auto 100%;
  }

  .about-text {
    padding-top: 40px;
    text-align: center;
    margin-bottom: 40px;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    .about-text-title{
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 32px;
      width: 400px;
      height: 110px;
      background: url("./images/banner-text.png") no-repeat;
      background-size: 100% 100%;
    }
    .lang{
      padding-left: 30px;
      font-family: Arial;
    }
    p{
      font-size: 20px;
      color: #231916;
      line-height: 32px;
      text-align: left;
      margin-bottom: 32px;
    }
  }

}
</style>
